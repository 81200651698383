import { LazyLoadImage } from "react-lazy-load-image-component";
import img from '../../../images/LogoNav.svg';
import { useEffect } from "react";

const Navbar = ({showLink = true}) => {
  useEffect(() => {
    function stickNavbar() {
      if (window.scrollY > 100) {
        document.getElementById("Navbar").classList.add("stickNavbar");
      }
      if (window.scrollY === 610 || window.scrollY === 1307 || window.scrollY === 0) {
        document.getElementById("Navbar").classList.remove("stickNavbar");
      }
    }

    window.addEventListener('scroll', stickNavbar);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  return (
    <div className="Navbar" id="Navbar">
      <div className="nav_logo" href="#الرئيسية">
        <span>كمّون</span>
        <span>
          <LazyLoadImage
            className='Logo'
            alt='Logo'
            src={img}
            width='auto'
            height='auto'
            title="hi"
          />
        </span>
      </div>
      <div className="nav_items" style={{
        visibility:showLink ? 'visible' : 'hidden'
      }}>
        {['الرئيسية', 'الميزات', 'لمحة عامة', "تواصل معنا"].map((item) => (
          <a className='Link' key={item} href={"#" + item}>
            {item}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Navbar;