import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PrivacyPolicy from './page/PrivacyPolicy';

const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>,
    },
    {
        path: "/privacy_policy",
        element: <PrivacyPolicy/>,
      },
  ]);

 const root = ReactDOM.createRoot(document.getElementById('root'));
     root.render(
        <RouterProvider  router={router}/>
     );