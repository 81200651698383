import React from 'react'
import Navbar from '../components/Home/Navbar/Navbar'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import img from '../images/LogoNav.svg';

function PrivacyPolicy() {
  return (
    <span style={{ direction: "rtl" }}>
      <LazyLoadImage
        style={{
          width: '70px',
          height: '70px',
          objectFit: 'contain',
          display: 'block',
          margin: '40px auto',

        }}
        alt='Logo'
        src={img}
        width='auto'
        height='auto'
        title="hi"
      />

      <h3 className='privacy_title'> سياسة استحدام تطبيق كمون </h3>

      <div style={{
        padding: "40px"
      }}>

        شكراً لاختيارك أن تكون جزءاً من مجتمعنا على
        Kammun.com.
        <br/>
        <br/>

        نحن ملتزمون بحماية معلوماتك الشخصية وحقك في الخصوصية. إذا كانت لديك أي أسئلة أو مخاوف بشأن سياستنا أو ممارستنا فيما يتعلق بمعلوماتك الشخصية، فيرجى الاتصال بنا على support@kammun.com.

        <br/>

        عندما تزور تطبيق الهاتف المحمول الخاص بنا وتستخدم خدماتنا، فإنك تأتمننا على معلوماتك الشخصية. نحن نأخذ خصوصيتك على محمل الجد.
        <br/>
        
         في سياسة الاستخدام هذه، نسعى إلى شرح بأوضح طريقة ممكنة ما هي المعلومات التي نجمعها وكيف نستخدمها وما هي حقوقك فيما يتعلق بها. نأمل أن تخصص بعض الوقت لقراءتها بعناية لأنها مهمة. إذا كانت هناك أي شروط في سياسة الاستخدام هذه لا توافق عليها، فيرجى التوقف عن استخدام تطبيقاتنا وخدماتنا. يرجى قراءة سياسة الاستخدام هذه بعناية لأنها ستساعدك على اتخاذ قرارك بشأن استخدام التطبيق.

        <br/>
        تعريف بالتطبيق وعمله:
        <br/>

        كمّون هو تطبيق للهواتف الذكية تقوم من خلاله بطلب مستلزماتك المنزلية من بقوليات وألبان وأجبان وغيرها وبعد تثبيت الطلب وتأكيده يقوم فريق كمّون بمحاولة تأمين هذه المستلزمات بالشكل الأنسب عن طريق شرائها لك من (محلات البقالة - السوبر ماركت -الميني ماركت -محلات الألبان والأجبان - المحامص - الأكشاك مكتبات.. إلخ) بحسب نوع المنتج المطلوب ويضمن فريق كمّون محاولة تأمين المنتجات المطلوبة بأفضل سعر وأعلى جودة بحيث تكون مطابقة تماماً لمتطلبات العميل. لا يمتلك التطبيق أي مستودعات أو بضائع ولا يقوم بالبيع المباشر لأي شخص بل يقوم بشراء جميع المنتجات من السوق بحسب المتوفر منها.
        <br/>

        الفئات المستهدفة:
        يستهدف تطبيق كمّون الأشخاص القاطنين بمناطق غير مخدمة من قبل محلات البقالة والسوبر ماركت لتوصيل تلك الاحتياجات لهم.
        <br/>

        توصيف عمل التطبيق:
        يقوم تطبيق كمون بمرحلة أولية بالبحث عن أكثر المنتجات حاجة وطلباً ضمن السوق المحلية ومن ثم تصويرها وتنزيلها ضمن التطبيق حتى يتمكن المستخدمين من مشاهدتها وطلبها عن طريق التطبيق. يقوم المستخدم بتجهيز الطلب عن طريق التطبيق وتثبيت الطلب. بعد تثبيت الطلب يقوم عامل التوصيل بتجميع تلك الطلبات من الأماكن الأنسب حسب طلب الزبون وإيصاله للعنوان المكتوب مع الطلب. يتقاضى التطبيق أجور توصيل الطلب وهي تمثل المصدر الأساسي لربح التطبيق.
        <br/>

        حقوق المستخدم:
        إلغاء الطلب قبل قبوله من قبل فريق التوصيل.
        رفض استلام الطلب بحال وجود أي خطأ ضمن المنتجات.
        إعادة أي منتج يوجد به خلل تصنيع أو عيب أو تخريب واضح أثناء عملية التوصيل.
        اختيار وقت ووسيلة التوصيل المناسبة له.
        إعلامه بحال تعديل أي سعر عن طريق الاتصال أو عن طريق الفاتورة الظاهرة ضمن التطبيق ويحق له الاعتذار عن استلام المنتج المعدل.
        يحق للمستخدم إعادة المنتج بحال لم يكن ضمن الجودة المناسبة أو السعر المناسب له.
        <br/>

        مسؤوليات المستخدم:
        دفع قيمة المنتجات التي قام فريق التوصيل بشرائها له.
        التأكد من جميع المنتجات التي يقوم بإضافتها ضمن سلة المشتريات.
        دفع أجور التوصيل الخاصة بالطلب.
        التعامل باحترام مع مزودي خدمة كمّون أو أي شخص يتواصل باسم الشركة.
        <br/>

        آلية الحماية:
        يقوم التطبيق بتشفير الاتصال بينه وبين المخدم عن طريق تشفير المفتاح العام، يستخدم هذا النوع من التشفير قوة مفتاحين هما سلاسل طويلة من الأرقام المولدة عشوائياً. أحدهما يسمى المفتاح الخاص والآخر المفتاح العام. المفتاح العام معروف للخادم ومتاح في المجال العام. يمكن استخدامه لتشفير أي رسالة.
        <br/>

        الخصوصية:
        لا يقوم التطبيق بجمع أي معلومات حساسة من المستخدم حتى أنه لا يقوم بطلب أي صلاحيات من المستخدم بعد تثبيت التطبيق.

        حقوق الجهة المسؤولة عن التطبيق:
        الحصول على المستحقات المادية من المستخدمين عند وصول الطلب والتأكد من جميع محتوياته.
        الحصول على أجرة التوصيل الخاصة بالتطبيق والتي تشكل المصدر الأساسي للربح.
        الحصول على رقم للتواصل وعنوان لتوصيل المنتجات.
        حظر المستخدم ومنعه من استخدام التطبيق بحال ظهور محالة خداع أو غش أو تعذر عن دفع قيمة الطلب بعد توصيل الطلب أو التعامل بشكل غير لائق أو مسيء مع أي فرد من أفراد كمّون.
        يحق لشركة كمّون تغيير الاسعار ضمن الطلب توافقا مع اسعار الموردين والسوق المحلية.
        يحق لشركة كمّون إيقاف الخدمة بأي وقت كان عن أي زبون بدون ذكر سبب الإيقاف.
        يحق للشركة حظر أي مستخدم بدون الحاجة لأي تبرير أو تبليغ.
        <br/>

        واجبات الجهة المسؤولة عن التطبيق:
        توصيل الطلب للزبون بالشكل المطابق تماماً لمتطلباته.
        إعلام المستخدم بالبدائل المتوفرة ضمن السوق المحلي بحال تعذر الحصول على المنتج بالمتطلبات المطابقة لطلب الزبون.
        تحديث قائمة المتجات بحيث تقوم بتنزيل وتنويع المنتجات باستمرار.
        <br/>

        سياية المرتجعات:
        شركة كمّون غير مسؤولة عن أي نقص بالطلبات بعد استلام الطلب ويعد الزبون مسؤولا عن التأكد من تواجد كامل الطلبات قبل تسليم المبلغ بالكامل، ويحق له مدة لا تزيد عن ال٥ أو ٧ دقائق للتأكد من الطلبات وجودة المواد، ويحق له إرجاع أي مادة تالفة أو ليست بجودة مناسبة على الباب. لا يحق للزبون استراد أي مبالغ لقاء مواد تالفة تم رميها. يحق للزبون إرجاع أي مادة تم طلبها إذا لم تكن قابلة للتلف ومدتها سارية الصلاحية خلال خمسة عشرة يوماً من تاريخ الطلب وذلك من خلال طلبه اللاحق بدون أي أجور إضافية. يترتب اجور توصيل على الزبون في حال كانت المادة قابلة للتلف ولم يقم بانتظار طلب لاحق لإرجاعها . يترتب على الزبون أجور توصيل تضاف على طلبه المقبل في حال لم يستلم طلب لأي سبب من الاسباب.
        <br/>

        الإجراءات التي سيتخذها التطبيق بحال مخالفة سياسة الاستخدام:
        بحال مخالفة المستخدم لسياسية الاستخدام سوف يقوم التطبيق برفض أي طلب من المخالف وربما حظره ومنعه من تصفح التطبيق بشكل كامل.
        <br/>

        تعديل سياسة الاستخدام:
        يقوم التطبيق بتعديل سياسة الاستخدام عند الحاجة وسوف يقوم بإعلام المستخدمين عن طريق
        الموقع الإلكتروني الخاص بالتطبيق
        www.kammun.com
        <br/>

        أو عن طريق وسائل التواصل الاجتماعي الخاصة بالتطبيق أو أي وسيلة مناسبة تجدها الجهة المسؤولة عن التطبيق.

      </div>
    </span>
  )
}

export default PrivacyPolicy